import megabillboardElement from "../zones/megabillboard/index.html";
import billboardElement from '../zones/billboard/index.html';
import leaderboardMobileElement from "../zones/leaderboard/mobile/index.html";
import leaderboardDesktopElement from "../zones/leaderboard/desktop/index.html";
import inarticle1MobileElement from "../zones/inarticle/mobile/inarticle1.html";
import inarticle1DesktopElement from '../zones/inarticle/desktop/inarticle1.html';
import inarticle2MobileElement from "../zones/inarticle/mobile/inarticle2.html";
import inarticle2DesktopElement from '../zones/inarticle/desktop/inarticle2.html';
import inarticle3MobileElement from "../zones/inarticle/mobile/inarticle3.html";
import inarticle3DesktopElement from '../zones/inarticle/desktop/inarticle3.html';
import infeed1MobileElement from '../zones/infeed/mobile/infeed1.html';
import infeed2MobileElement from '../zones/infeed/mobile/infeed2.html';
import infeed3MobileElement from '../zones/infeed/mobile/infeed3.html';
import infeed1DesktopElement from '../zones/infeed/desktop/infeed1.html';
import infeed2DesktopElement from '../zones/infeed/desktop/infeed2.html';
import infeed3DesktopElement from '../zones/infeed/desktop/infeed3.html';
import custom1Element from '../zones/custom/custom1.html';
import custom2Element from '../zones/custom/custom2.html';
import skinleftElement from '../zones/skin/left.html';
import skinrightElement from '../zones/skin/right.html';
import mr1Element from '../zones/mr/desktop/mr1.html';
import mr2Element from '../zones/mr/desktop/mr2.html';
import mr1CategoryElement from '../zones/mr/desktop/category-article/mr1.html';
import mr2CategoryElement from '../zones/mr/desktop/category-article/mr2.html';
import stickyMobileElement from '../zones/sticky/mobile/index.html';
import stickyDesktopElement from '../zones/sticky/desktop/index.html';
import oop1Element from '../zones/oop/oop1.html';
import { campaigns, Helpers, zones, fetchData, stickyCodeMegabillboardV1, stickyCodeInarticle1V1 } from "../../utils/index";

/**
 * @param {string}  publisher
 * @param {string}  channel
 * @param {string}  platform
 * @param {Array}  topics
 * @param {boolean}  isStagingEnv
 */

export async function Channels(publisher, channel, platform, topics, isStagingEnv) {
    const helpers = new Helpers();

    const ps_mb = document.getElementById('IDN_PS_MegaBillboard');
    const ps_lb = document.getElementById('IDN_PS_Leaderboard');
    const mb = document.getElementById('IDN_MegaBillboard');
    const billboard = document.getElementById('IDN_Billboard');
    const lb = document.getElementById('IDN_Leaderboard');
    const ia1 = document.getElementById('IDN_InArticle1');
    const ia2 = document.getElementById('IDN_InArticle2');
    const ia3 = document.getElementById('IDN_InArticle3');
    const if1 = document.getElementById('IDN_InFeed1');
    const if2 = document.getElementById('IDN_InFeed2');
    const if3 = document.getElementById('IDN_InFeed3');
    const ct1 = document.getElementById("IDN_Custom1");
    const ct2 = document.getElementById("IDN_Custom2");
    const sl = document.getElementById("IDN_SkinLeft");
    const sr = document.getElementById("IDN_SkinRight");
    const mr1 = document.getElementById("IDN_MR1");
    const mr2 = document.getElementById("IDN_MR2");
    const oop1 = document.getElementById("IDN_OOP1");
    const sticky = document.getElementById('IDN_Sticky');
    const native_ads = document.getElementById("IDN_ReviveCustomAds");
    const partner_1 = document.getElementById("IDN_Partner1");
    const interstitial = document.getElementById("IDN_Interstitial");
    const banners = [...Array(8)].map((_, i) => document.getElementById(`IDN_Banner${i + 1}`));

    const isStaging = isStagingEnv ? new URLSearchParams(window.location.search).get('ads_environment') === 'production'? false: true : false;
    const isUsingMagnite = publisher === "IDNTimes" || publisher === 'Popbela' || (publisher === 'Popmama' && platform === 'Desktop');
    const isUsingShowheroes = publisher === 'IDNTimes' || publisher === 'Popbela' || publisher === 'Popmama';
    const isHomepage = window.location.pathname === '/';
    const isRecipeDetailPage = window.location.pathname.includes('/resep/');
    const isCategoryArticlePage = window.location.pathname === '/artikel/kuliner' || window.location.pathname === '/artikel/nutrisi' || window.location.pathname === '/artikel/tips' || window.location.pathname === '/event';

    const isUsingNextJs = publisher === 'Popbela' || publisher === 'Fortune' || publisher === 'Yummy';

    const reviveId = isStaging
        ? "514e6345f2a753cbdbc878c567d99848"
        : "538b56b5eca99c2d9b60774e42ecc44f";

    const detailZone = zones(publisher);
    const detailCampaigns = campaigns(publisher);

    const listBannerCampaign = detailCampaigns.filter((campaign) => campaign.type === 'banner')[0]?.ids;

    const ad_unit = isStaging ? 'Staging' : `${publisher}${platform}/${channel}`;

    const detailMB = await fetchData(`https://cdn.${isStaging ? 'sateklopo.com' : 'idn.media'}/ads/interval/megabillboard.json`);

    function defineMobileSlot() {
        var code = `
            var pbjs = pbjs || {};
            var gptadslots = [];
            var gpt_slot_objects = [];

            pbjs.que = pbjs.que || [];
            window.googletag = window.googletag || {cmd: []};

           googletag.cmd.push(function() {
                ${oop1 !== null 
                    ? `googletag.defineOutOfPageSlot('/253109699/${ad_unit}', 'div-gpt-ad-oop1')
                    .setTargeting('pos', ['oop1'])
                    .addService(googletag.pubads());` : ''}
                        
                ${interstitial !== null ? `googletag.defineOutOfPageSlot('/253109699/${publisher}${platform}/Interstitial',
                    googletag.enums.OutOfPageFormat.INTERSTITIAL)
                    .addService(googletag.pubads());` 
                    : ''}

                ${mb !== null
                ?   `gptadslots['div-gpt-ad-mega_billboard'] = googletag.defineSlot('/253109699/${ad_unit}', [[320, 480]], "div-gpt-ad-mega_billboard")
                    .setTargeting('pos', ['mega_billboard'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-mega_billboard']);
                    googletag.display('div-gpt-ad-mega_billboard');
                    ${stickyCodeMegabillboardV1(detailZone.scrollHeight.mb ,detailMB[0].interval, detailMB[0].intervalHideCloseButton)}`
                    : ''}

                ${lb !== null
                ? `gptadslots['div-gpt-ad-leaderboard'] = googletag.defineSlot('/253109699/${ad_unit}', [[300,250],[320,100],[320,150]], "div-gpt-ad-leaderboard")
                    .setTargeting('pos', ['Leaderboard'])
                    .addService(googletag.pubads());
                    googletag.display('div-gpt-ad-leaderboard');
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-leaderboard']);

                    var isLb = true;
                    googletag.pubads().addEventListener("slotRenderEnded", (event) => { 
                        const slotId = event.slot.getSlotElementId();
                        var size = '';

                        if(slotId === 'div-gpt-ad-leaderboard') {
                            size = typeof event.size === "string" ? event.size : event.size?.join("x") ?? null;
                        }

                        function backToDefaultLeaderboard() {
                            if (document.getElementById('banner-placeholder') !== null) {
                                document.getElementById('banner-placeholder').classList.remove("sticky-banner-lb");       
                            }
                        }

                        function scrollLbFunction() {
                            if (document.body.scrollTop > ${detailZone.scrollHeight.lb} || document.documentElement.scrollTop > ${detailZone.scrollHeight.lb}) {
                                if(isLb && size === '320x100') {
                                    document.getElementById('banner-placeholder').classList.add("sticky-banner-lb");
                                    document.getElementById('banner-placeholder').style.top = "${detailZone.navbarHeight.mobile}px";
                                    setTimeout(backToDefaultLeaderboard, 3000)
                                    isLb = false;   
                                }
                            }
                            else {
                                backToDefaultLeaderboard();
                                isLb = true;
                            }
                        }

                        document.addEventListener('scroll', function(event) {
                            event.preventDefault();
                            scrollLbFunction();
                        });
                    });`
                : ""}

                ${ia1 !== null
                ? `gptadslots['div-gpt-ad-inarticle1'] = googletag.defineSlot('/253109699/${ad_unit}', [[1,1],[300,250],[320,100],[320,480]], "div-gpt-ad-inarticle1")
                    .setTargeting('pos', ['InArticle1'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-inarticle1']);
                    googletag.display('div-gpt-ad-inarticle1');
                    ${stickyCodeInarticle1V1()}
                    document.getElementById('ads-scrollpage-top-inarticle1').style.top = "${detailZone.navbarHeight.mobile}px"`
                : ""}

                ${ia2 !== null
                ? `gptadslots['div-gpt-ad-inarticle2'] = googletag.defineSlot('/253109699/${ad_unit}', [[300,250],[320,100]], "div-gpt-ad-inarticle2")
                    .setTargeting('pos', ['InArticle2'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-inarticle2']);
                    googletag.display('div-gpt-ad-inarticle2');

                    document.getElementById('ads-scrollpage-top-inarticle2').style.top = "${detailZone.navbarHeight.mobile}px"`
                : ""}

                ${ia3 !== null
                ? `gptadslots['div-gpt-ad-inarticle3'] = googletag.defineSlot('/253109699/${ad_unit}', [[300,250],[320,100]], "div-gpt-ad-inarticle3")
                    .setTargeting('pos', ['InArticle3'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-inarticle3']);
                    googletag.display('div-gpt-ad-inarticle3');

                    document.getElementById('ads-scrollpage-top-inarticle3').style.top = "${detailZone.navbarHeight.mobile}px"`
                : ""}

                ${if1 !== null ? `gptadslots['div-gpt-ad-infeed1'] = googletag.defineSlot('/253109699/${ad_unit}', [[300,250],[320,100],[336,280]], "div-gpt-ad-infeed1")
                    .setTargeting('pos', ['InFeed1'])
                    .addService(googletag.pubads());
                    googletag.display('div-gpt-ad-infeed1');
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-infeed1']);

                    document.getElementById('ads-scrollpage-top-infeed1').style.top = "${detailZone.navbarHeight.mobile}px"` : ''}

                ${if2 !== null ? `gptadslots['div-gpt-ad-infeed2'] = googletag.defineSlot('/253109699/${ad_unit}', [[300,250],[320,100],[320,480],[320,280]], "div-gpt-ad-infeed2")
                    .setTargeting('pos', ['InFeed2'])
                    .addService(googletag.pubads());
                    googletag.display('div-gpt-ad-infeed2');
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-infeed2']);

                    document.getElementById('ads-scrollpage-top-infeed2').style.top = "${detailZone.navbarHeight.mobile}px"` : ''}

                ${if3 !== null ? `gptadslots['div-gpt-ad-infeed3'] = googletag.defineSlot('/253109699/${ad_unit}', [[300,250],[320,100],[336,280]], "div-gpt-ad-infeed3")
                    .setTargeting('pos', ['InFeed3'])
                    .addService(googletag.pubads());
                    googletag.display('div-gpt-ad-infeed3');
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-infeed3']);

                    document.getElementById('ads-scrollpage-top-infeed3').style.top = "${detailZone.navbarHeight.mobile}px"` : ''}
                
                ${sticky !== null ? `gptadslots['div-gpt-ad-sticky'] = googletag.defineSlot('/253109699/${ad_unit}', [[320,50],[300,100],[320,100]], "div-gpt-ad-sticky")
                    .setTargeting('pos', ['Sticky'])
                    .addService(googletag.pubads());
                    googletag.display('div-gpt-ad-sticky');
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-sticky']);

                    const footer_banner = document.getElementsByClassName("footer_banner")[0];
                    const svg = document.querySelectorAll(".ssb svg")[0];
                    const footerHeight = document.getElementsByClassName("footer_banner")[0].offsetHeight;
                    let show = false;
               
                    const isVisible = document.getElementById('google_image_div') !== null;
                    
                    googletag.pubads().addEventListener("slotRenderEnded", (event) => {
                        if(event.slot.getSlotElementId() === 'div-gpt-ad-sticky' && !event.slotContentChanged) {
                            footer_banner.style.bottom = "-108px";
                            svg.style.transform = "rotate(-180deg)";
                        }
                    });

                    document.getElementById("idn_ads_bottom_sticky_close_button").addEventListener("click", function() {
                        footer_banner.style.bottom = show ? "0" : "-108px";
                        svg.style.transform = show ? "rotate(0deg)" :  "rotate(-180deg)";
                        show = !show;
                    }, false);` : ''}
            
                ${ct1 !== null
                    ? `gptadslots['div-gpt-ad-custom1'] = googletag.defineSlot('/253109699/${ad_unit}', [[1,1]], 'div-gpt-ad-custom1')
                    .setTargeting('pos', ['custom1'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-custom1']);
                    googletag.display('div-gpt-ad-custom1');` : ""}
                    
                ${ct2 !== null
                    ? `gptadslots['div-gpt-ad-custom2'] = googletag.defineSlot('/253109699/${ad_unit}', [[1,1]], 'div-gpt-ad-custom2')
                    .setTargeting('pos', ['custom2'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-custom2']);
                    googletag.display('div-gpt-ad-custom2');` : ""}

                ${topics ? `googletag.pubads().setTargeting('topic', [${topics.map((topic) => `'${topic}'`)}])` : ''}
                    
                googletag.pubads().enableLazyLoad({ fetchMarginPercent: -1 });
                    
                ${isUsingMagnite ? publisher === 'IDNTimes' ?
                `   googletag.pubads().enableSingleRequest();
                    googletag.enableServices();`
                : "" : ""}
                
                ${isUsingMagnite
                ? `function callAdserver(gptSlots) {
                        if (pbjs.adserverCalled) return;
                        pbjs.adserverCalled = true;
                    }
        
                    pbjs.que.push(function() {
                        pbjs.rp.requestBids({
                            callback: callAdserver,
                                gptSlotObjects: gpt_slot_objects
                        });
                    });
        
                    setTimeout(function() {
                        callAdserver(gpt_slot_objects);
                    }, 3500);`
                : ""}
            });`;
        helpers.appendChild(code);
    }

    function defineDesktopSlot() {
        var code = `
            var pbjs = pbjs || {};
            var firstVisit = true;
            var gptadslots = [];
            var gpt_slot_objects = [];

            pbjs.que = pbjs.que || [];
            window.googletag = window.googletag || {cmd: []};
            
            googletag.cmd.push(function() {
                ${oop1 !== null 
                    ? `googletag.defineOutOfPageSlot('/253109699/${ad_unit}', 'div-gpt-ad-oop1')
                    .setTargeting('pos', ['oop1'])
                    .addService(googletag.pubads());` : ''}
                    
                ${interstitial !== null ? `googletag.defineOutOfPageSlot('/253109699/${publisher}${platform}/Interstitial',
                    googletag.enums.OutOfPageFormat.INTERSTITIAL)
                    .addService(googletag.pubads());` 
                    : ''}

                ${billboard !== null 
                ? `gptadslots['div-gpt-ad-billboard'] = googletag.defineSlot('/253109699/${ad_unit}', [[728,90],[970,90],[970,250]], "div-gpt-ad-billboard")
                    .setTargeting('pos', ['billboard'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-billboard']);
                    googletag.display('div-gpt-ad-billboard');
                    
                    document.getElementById('ads-scrollpage-top-billboard').style.top = "${detailZone.navbarHeight.desktop}px"` 
                : ''}

                ${lb !== null
                    ? `gptadslots['div-gpt-ad-leaderboard'] = googletag.defineSlot('/253109699/${ad_unit}', [[728,90]], "div-gpt-ad-leaderboard")
                    .setTargeting('pos', ['Leaderboard'])
                    .addService(googletag.pubads());
                    googletag.display('div-gpt-ad-leaderboard');
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-leaderboard']);
                    
                    var isLb = true;
                    googletag.pubads().addEventListener("slotRenderEnded", (event) => { 
                        const slotId = event.slot.getSlotElementId();
                        var size = '';

                        if(slotId === 'div-gpt-ad-leaderboard') {
                            size = typeof event.size === "string" ? event.size : event.size?.join("x") ?? null;
                        }

                        function backToDefaultLeaderboard() {
                            document.getElementById('wrapper-IDN_Leaderboard').classList.remove("sticky-banner-lb");       
                        }

                        function scrollLbFunction() {
                            if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
                                if(isLb) {
                                    document.getElementById('wrapper-IDN_Leaderboard').classList.add("sticky-banner-lb");   
                                    setTimeout(backToDefaultLeaderboard, 3000)
                                    isLb = false;   
                                }
                            }
                            else {
                                backToDefaultLeaderboard();
                            }
                        }

                        document.addEventListener('scroll', function(event) {
                            event.preventDefault();
                            scrollLbFunction();
                        });
                    });`
                : ""}

                ${ia1 !== null
                    ? `gptadslots['div-gpt-ad-inarticle1'] = googletag.defineSlot('/253109699/${ad_unit}', [[1,1],[728,90],[300,250],[336,280],[320,480]], "div-gpt-ad-inarticle1")
                        .setTargeting('pos', ['InArticle1'])
                        .addService(googletag.pubads());
                        googletag.enableServices();
                        gpt_slot_objects.push(gptadslots['div-gpt-ad-inarticle1']);
                        googletag.display('div-gpt-ad-inarticle1');
                        
                        document.getElementById('ads-scrollpage-top-inarticle1').style.top = "${detailZone.navbarHeight.desktop}px"`
                    : ""}

                ${ia2 !== null
                    ? `gptadslots['div-gpt-ad-inarticle2'] = googletag.defineSlot('/253109699/${ad_unit}', [[728,90]], "div-gpt-ad-inarticle2")
                        .setTargeting('pos', ['InArticle2'])
                        .addService(googletag.pubads());
                        googletag.enableServices();
                        gpt_slot_objects.push(gptadslots['div-gpt-ad-inarticle2']);
                        googletag.display('div-gpt-ad-inarticle2');
    
                        document.getElementById('ads-scrollpage-top-inarticle2').style.top = "${detailZone.navbarHeight.desktop}px"`
                    : ""}
                
                ${if1 !== null ? `gptadslots['div-gpt-ad-infeed1'] = googletag.defineSlot('/253109699/${ad_unit}', [[728,90],[300,250],[336,280]], "div-gpt-ad-infeed1")
                    .setTargeting('pos', ['InFeed1'])
                    .addService(googletag.pubads());
                    googletag.display('div-gpt-ad-infeed1');
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-infeed1']);

                    document.getElementById('ads-scrollpage-top-infeed1').style.top = "${detailZone.navbarHeight.desktop - (isRecipeDetailPage ? -12 : 0)}px"` : ''}
                
                ${if2 !== null ? `gptadslots['div-gpt-ad-infeed2'] = googletag.defineSlot('/253109699/${ad_unit}', [[728,90]], "div-gpt-ad-infeed2")
                    .setTargeting('pos', ['InFeed2'])
                    .addService(googletag.pubads());
                    googletag.display('div-gpt-ad-infeed2');
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-infeed2']);

                    document.getElementById('ads-scrollpage-top-infeed2').style.top = "${detailZone.navbarHeight.desktop}px"` : ''}
                
                ${if3 !== null ? `gptadslots['div-gpt-ad-infeed3'] = googletag.defineSlot('/253109699/${ad_unit}', [[728,90]], "div-gpt-ad-infeed3")
                    .setTargeting('pos', ['InFeed3'])
                    .addService(googletag.pubads());
                    googletag.display('div-gpt-ad-infeed3');
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-infeed3']);

                    document.getElementById('ads-scrollpage-top-infeed3').style.top = "${detailZone.navbarHeight.desktop}px"` : ''}
    
                ${ia3 !== null
                ? `gptadslots['div-gpt-ad-inarticle3'] = googletag.defineSlot('/253109699/${ad_unit}', [[728,90]], "div-gpt-ad-inarticle3")
                    .setTargeting('pos', ['InArticle3'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-inarticle3']);
                    googletag.display('div-gpt-ad-inarticle3');

                    document.getElementById('ads-scrollpage-top-inarticle3').style.top = "${detailZone.navbarHeight.desktop}px"`
                : ""}
    
                ${sl !== null
                ? `gptadslots['div-gpt-ad-skin_left'] = googletag.defineSlot('/253109699/${ad_unit}', [[120,600], [160,600]], "div-gpt-ad-skin_left")
                    .setTargeting('pos', ['Skin_Left'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-skin_left']);
                    googletag.display('div-gpt-ad-skin_left');`
                : ""}

                ${sr !== null
                ? `gptadslots['div-gpt-ad-skin_right'] = googletag.defineSlot('/253109699/${ad_unit}', [[120,600], [160,600]], "div-gpt-ad-skin_right")
                    .setTargeting('pos', ['Skin_Right'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-skin_right']);
                    googletag.display('div-gpt-ad-skin_right');`
                : ""}
                
                ${mr1 !== null
                ? `gptadslots['div-gpt-ad-mr1'] = googletag.defineSlot('/253109699/${ad_unit}', [[300,250], [300,600], [336,280]], "div-gpt-ad-mr1")
                    .setTargeting('pos', ['MR1'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-mr1']);
                    googletag.display('div-gpt-ad-mr1');
                    
                    document.getElementById('ads-scrollpage-top-mr1').style.top = "${detailZone.navbarHeight.desktop}px"`
                : ""}

                ${mr2 !== null
                ? `gptadslots['div-gpt-ad-mr2'] = googletag.defineSlot('/253109699/${ad_unit}', [[300,250], [300,600], [336,280]], "div-gpt-ad-mr2")
                    .setTargeting('pos', ['MR2'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-mr2']);
                    googletag.display('div-gpt-ad-mr2');
                    
                    document.getElementById('ads-scrollpage-top-mr2').style.top = "${detailZone.navbarHeight.desktop}px"`
                : ""}

                ${ct1 !== null
                ? `gptadslots['div-gpt-ad-custom1'] = googletag.defineSlot('/253109699/${ad_unit}', [[1,1]], 'div-gpt-ad-custom1')
                    .setTargeting('pos', ['custom1'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-custom1']);
                    googletag.display('div-gpt-ad-custom1');`  : ""}
                            
                ${ct2 !== null
                ? `gptadslots['div-gpt-ad-custom2'] = googletag.defineSlot('/253109699/${ad_unit}', [[1,1]], 'div-gpt-ad-custom2')
                    .setTargeting('pos', ['custom2'])
                    .addService(googletag.pubads());
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-custom2']);
                    googletag.display('div-gpt-ad-custom2');`  : ""}

                ${sticky !== null ? `gptadslots['div-gpt-ad-sticky'] = googletag.defineSlot('/253109699/${ad_unit}', [[728,90]], "div-gpt-ad-sticky")
                    .setTargeting('pos', ['Sticky'])
                    .addService(googletag.pubads());
                    googletag.display('div-gpt-ad-sticky');
                    googletag.enableServices();
                    gpt_slot_objects.push(gptadslots['div-gpt-ad-sticky']);

                    const footer_banner = document.getElementsByClassName("footer_banner")[0];
                    const svg = document.querySelectorAll(".ssb svg")[0];
                    const footerHeight = document.getElementsByClassName("footer_banner")[0].offsetHeight;
                    let show = false;
                    
                    const isVisible = document.getElementById('google_image_div') !== null;

                    googletag.pubads().addEventListener("slotRenderEnded", (event) => {
                        if(event.slot.getSlotElementId() === 'div-gpt-ad-sticky' && !event.slotContentChanged) {
                            footer_banner.style.bottom = "-114px";
                            svg.style.transform = "rotate(-180deg)";
                        }
                    });

                    document.getElementById("idn_ads_bottom_sticky_close_button").addEventListener("click", function () {
                        footer_banner.style.bottom = show ? "0" : "-114px";
                        svg.style.transform = show ? "rotate(0deg)" :  "rotate(-180deg)";
                        show = !show;
                    }, false);
                    ` : ''}

                ${topics ? `googletag.pubads().setTargeting('topic', [${topics.map((topic) => `'${topic}'`)}])` : ''}

                googletag.pubads().enableLazyLoad({ fetchMarginPercent: -1 });
                    
                ${isUsingMagnite ? publisher === 'IDNTimes' ?
                `   googletag.pubads().enableSingleRequest();
                    googletag.pubads().disableInitialLoad();
                    googletag.enableServices();`
                : "" : ""}
                
                ${isUsingMagnite
                ? `function callAdserver(gptSlots) {
                        if (pbjs.adserverCalled) return;
                        pbjs.adserverCalled = true;
                    }
        
                    pbjs.que.push(function() {
                        pbjs.rp.requestBids({
                            callback: callAdserver,
                                gptSlotObjects: gpt_slot_objects
                        });
                    });
        
                    setTimeout(function() {
                        callAdserver(gpt_slot_objects);
                    }, 3500);`
                : ""}
            });`;
        helpers.appendChild(code);
    }

    function displayMobileSlot() {
        if (isUsingMagnite) {
            let head = document.getElementsByTagName('head')[0];
            let scriptMagnite = document.createElement('script');
            scriptMagnite.src = "//micro.rubiconproject.com/prebid/dynamic/22166.js";
            head.appendChild(scriptMagnite);
        }
        if (ps_mb !== null) {
            ps_mb.style.display = "none";
        }
        if (ps_lb !== null) {
            ps_lb.style.display = "none";
        }
        if (mb !== null) {
            mb.innerHTML = megabillboardElement;
        }
        if (lb !== null) {
            lb.innerHTML = leaderboardMobileElement;
        }
        if (ia1 !== null) {
            ia1.innerHTML = inarticle1MobileElement;
        }
        if (ia2 !== null) {
            ia2.innerHTML = inarticle2MobileElement;
        }
        if (ia3 !== null) {
            ia3.innerHTML = inarticle3MobileElement;
        }

        if (if1 !== null) {
            if1.innerHTML = infeed1MobileElement;
        }

        if (if2 !== null) {
            if2.innerHTML = infeed2MobileElement;
        }
        if (if3 !== null) {
            if3.innerHTML = infeed3MobileElement;
        }
        if (sticky !== null) {
            sticky.innerHTML = stickyMobileElement;
        }
        if (ct1 !== null) {
            ct1.innerHTML = custom1Element;
        }
        if (ct2 !== null) {
            ct2.innerHTML = custom2Element;
        }
        if(oop1 !== null) {
            oop1.innerHTML = oop1Element;
        }
        if (native_ads !== null) {
            let ins = document.createElement("ins");
            const textnode = document.createTextNode("");
            ins.setAttribute("data-revive-zoneid", detailZone.reviveZoneId);
            ins.setAttribute("data-revive-id", reviveId);
            ins.appendChild(textnode);
            native_ads.appendChild(ins);

            let body = document.getElementsByTagName("body")[0];
            let scriptNativeAdsRevive = document.createElement("script");
            scriptNativeAdsRevive.src = `https://adsrevive.${isStaging ? "sateklopo.com" : "idn.media"}/www/delivery/asyncjs.php`;
            scriptNativeAdsRevive.async;
            body.appendChild(scriptNativeAdsRevive);
        }
        if (partner_1 !== null) {
            let head = document.getElementsByTagName("head")[0];
            let scriptPartner1 = document.createElement("script");
            const widgetID =
                platform === "Mobile"
                    ? detailZone.partner1.widgetID.mobile.is_not_infinite_scroll
                    : platform === "Desktop"
                        ? detailZone.partner1.widgetID.desktop
                        : detailZone.partner1.widgetID.amp;
            scriptPartner1.src = `https://jsc.mgid.com/${detailZone.partner1.id}.${widgetID}.js`;

            if (platform !== "AMP") {
                head.appendChild(scriptPartner1);
                partner_1.innerHTML = `<div id='M${detailZone.partner1.mid}ScriptRootC${widgetID}'></div>`;
            } else {
                partner_1.innerHTML = `<amp-embed width="600" height="600" layout="responsive" type="mgid" data-publisher="idntimes.com" data-widget="${widgetID}" data-container="M${detailZone.partner1.mid}ScriptRootC${widgetID}" data-block-on-consent="_till_responded"></amp-embed> `;
            }
        }
        if (document.getElementById("idnads_btn-close") !== null) {
            document.getElementById("idnads_btn-close").addEventListener("click", function () {
                const T = document.getElementById("idnads_header");
                if (T !== null) {
                    T.style.display = "none";
                }
            });
        }
    }

    function displayDesktopSlot() {
        if (billboard !== null) {
            billboard.innerHTML = billboardElement;
        }
        if (lb !== null) {
            lb.innerHTML = leaderboardDesktopElement;
        }
        if (ia1 !== null) {
            ia1.innerHTML = inarticle1DesktopElement;
        }
        if(ia2 !== null) {
            ia2.innerHTML = inarticle2DesktopElement;
        }
        if(ia3 !== null) {
            ia3.innerHTML = inarticle3DesktopElement;
        }
        if(if1 !== null) {
            if1.innerHTML = infeed1DesktopElement;
        }
        if(if2 !== null) {
            if2.innerHTML = infeed2DesktopElement;
        }
        if(if3 !== null) {
            if3.innerHTML = infeed3DesktopElement;
        }
        if(sl !== null) {
            sl.innerHTML = skinleftElement;
        }
        if(sr !== null) {
            sr.innerHTML = skinrightElement;
        }
        if(mr1 !== null) {
            mr1.innerHTML = isCategoryArticlePage ? mr1CategoryElement : mr1Element;
        }
        if(mr2 !== null) {
            mr2.innerHTML = isCategoryArticlePage ? mr2CategoryElement : mr2Element;
        }
        if (ct1 !== null) {
            ct1.innerHTML = custom1Element;
        }
        if (ct2 !== null) {
            ct2.innerHTML = custom2Element;
        }
        if(oop1 !== null) {
            oop1.innerHTML = oop1Element;
        }
        if(sticky !== null) {
            sticky.style.display = 'flex';
            sticky.style.justifyContent = 'center';
            sticky.innerHTML = stickyDesktopElement;

            if (document.getElementById("idnads-sticky-btn-close") != null) {
                document.getElementById("idnads-sticky-btn-close").addEventListener('click', function () {
                    const T = document.getElementById("idn_ads_bottom_sticky_content");
                    if (T !== null) {
                        T.style.visibility = "hidden";
                    }
                });
            }
        }
    }

    if (listBannerCampaign && listBannerCampaign.length !== 0) {
        let head = document.getElementsByTagName("head")[0];
        let scriptNativeAdsRevive = document.createElement("script");
        scriptNativeAdsRevive.src = `https://adsrevive.${isStaging ? "sateklopo.com" : "idn.media"}/www/delivery/asyncjs.php`;
        scriptNativeAdsRevive.async;
        head.appendChild(scriptNativeAdsRevive);

        banners.map((banner, index) => {
            if (banner !== null) {
                let ins = document.createElement("ins");
                const textnode = document.createTextNode("");
                ins.setAttribute("data-revive-zoneid", listBannerCampaign[index]);
                ins.setAttribute("data-revive-id", reviveId);
                ins.appendChild(textnode);
                banner.appendChild(ins);
            }
        })
    }
    
    if (isUsingShowheroes && !isHomepage && channel !== 'Quiz') {
        let head = document.getElementsByTagName("head")[0];
        let scriptShowheroes = document.createElement("script");
        scriptShowheroes.setAttribute('data-wid', 'auto');
        scriptShowheroes.type = 'text/javascript';
        scriptShowheroes.src = `https://content.viralize.tv/display/?zid=${platform === 'Desktop' ? zones(publisher, channel).partner2.id.desktop : zones(publisher, channel).partner2.id.mobile}`;
        head.appendChild(scriptShowheroes);
    }

    function outstreamVideo() {
        
        const elementTopThreshold = document.getElementById('IDN_Partner2'); //Showheroes
        const elementBottomThreshold = document.getElementById('IDN_Partner1'); //MGID
        
        if(elementTopThreshold !== null && elementBottomThreshold !== null) {
            window.addEventListener('scroll', () => { 
                if ((window.pageYOffset - 50) <= (elementTopThreshold.offsetTop +  (elementTopThreshold.offsetParent.offsetTop - elementTopThreshold.offsetParent.offsetParent.offsetTop))) { 
                    if(document.evaluate('//div[@class[contains(., "brand-showheroes")]]/div[@id[contains(., "wrapper")]]', document, null, XPathResult.ANY_TYPE, null) !== null) {
                        document.evaluate('//div[@class[contains(., "brand-showheroes")]]/div[@id[contains(., "wrapper")]]', document, null, XPathResult.ANY_TYPE, null).iterateNext().style.visibility = 'hidden';
                    }
                    if(document.getElementById('floatingbanner') !== null) {
                        document.getElementById('floatingbanner').style.visibility = 'visible';
                    }
                } else {
                    if(document.evaluate('//div[@class[contains(., "brand-showheroes")]]/div[@id[contains(., "wrapper")]]', document, null, XPathResult.ANY_TYPE, null) !== null) {
                        document.evaluate('//div[@class[contains(., "brand-showheroes")]]/div[@id[contains(., "wrapper")]]', document, null, XPathResult.ANY_TYPE, null).iterateNext().style.visibility = 'visible';
                    }
                    if(document.getElementById('floatingbanner') !== null) {
                        document.getElementById('floatingbanner').style.visibility = 'hidden';
                    }
                }
    
                if((window.pageYOffset - 50) >= (elementBottomThreshold.offsetTop +  (elementBottomThreshold.offsetParent.offsetTop - elementBottomThreshold.offsetParent.offsetParent.offsetTop))) {
                    if(document.evaluate('//div[@class[contains(., "brand-showheroes")]]/div[@id[contains(., "wrapper")]]', document, null, XPathResult.ANY_TYPE, null) !== null) {
                        document.evaluate('//div[@class[contains(., "brand-showheroes")]]/div[@id[contains(., "wrapper")]]', document, null, XPathResult.ANY_TYPE, null).iterateNext().style.visibility = 'hidden';
                    }
                    if(document.getElementById('floatingbanner') !== null) {
                        document.getElementById('floatingbanner').style.visibility = 'visible';
                    }
                }
            });
        }
        
        const idnLiveEmbedScript = document.createElement('script');

        const node = document.createTextNode(`
            var FloatingAds = function() {
            var currentTime = new Date();
            var utcHour = currentTime.getUTCHours();
            var gmt7Hour = (utcHour + 7) % 24;

            if (gmt7Hour >= 16 && gmt7Hour < 24) {
            var floatingBanner = document.createElement('div');
            floatingBanner.id = "floatingbanner";
            floatingBanner.style.position = "relative";
            floatingBanner.style.backgroundColor = "black";
            floatingBanner.style.width = "190px";
            floatingBanner.style.height = "108px";
            floatingBanner.style.left = "-260px";
            floatingBanner.style.bottom = "0px";
            floatingBanner.style.zIndex = "999";
            floatingBanner.style.transition = "all .5s linear 0s";
            floatingBanner.style.boxShadow = "1px 1px 10px #ccc";
            floatingBanner.style.borderRadius = "8px";
            var iframe = document.createElement('iframe');
            iframe.width = "200";
            iframe.height = "120";
            // link embed
            iframe.src = "https://short.idn.app/embedidntimes?auto_play=1&mute=1";
            iframe.frameBorder = "0";
            iframe.scrolling = "no";
            iframe.allowFullscreen = "true";
            iframe.style.position = "absolute";
            iframe.style.width = "200px";
            iframe.style.height = "120px";
            iframe.style.left = "0px";
            iframe.style.top = "0px";
            iframe.style.zIndex = "1";
            iframe.style.borderRadius = "8px";
            floatingBanner.appendChild(iframe);
            var bottomStickyContent = window.parent.document.getElementsByClassName("bottom-sticky-content")[0];
            if (bottomStickyContent) {
                bottomStickyContent.prepend(floatingBanner);
            }
            var counter = 3;
            var countdown = document.createElement('div');
            countdown.className = "countdown";
            countdown.style.position = "absolute";
            countdown.style.zIndex = "999";
            countdown.style.top = "-27px";
            countdown.style.right = "0";
            countdown.style.fontSize = "14px";
            countdown.style.color = "white";
            countdown.style.backgroundColor = "#B2B2B2";
            countdown.style.width = "25px";
            countdown.style.height = "25px";
            countdown.style.display = "flex";
            countdown.style.alignItems = "center";
            countdown.style.justifyContent = "center";
            countdown.style.borderRadius = "50%";
            countdown.style.cursor = "pointer";
            countdown.textContent = counter;
            floatingBanner.appendChild(countdown);
            var countdownInterval;
            setTimeout(function() {
                floatingBanner.style.transition = "all .5s linear 0s";
                floatingBanner.style.left = "10px";
                countdownInterval = setInterval(function() {
                counter--;
                countdown.textContent = counter;
                if (counter === 0) {
                    clearInterval(countdownInterval);
                    countdown.remove();
                    var closeBtn = document.createElement('div');
                    closeBtn.className = "close_btn";
                    closeBtn.style.position = "absolute";
                    closeBtn.style.zIndex = "999";
                    closeBtn.style.top = "-28px";
                    closeBtn.style.right = "0px";
                    closeBtn.style.backgroundColor = "#FF0506";
                    closeBtn.style.fontSize = "14px";
                    closeBtn.style.fontWeight = "bold";
                    closeBtn.style.color = "white";
                    closeBtn.style.cursor = "pointer";
                    closeBtn.style.width = "25px";
                    closeBtn.style.height = "25px";
                    closeBtn.style.display = "flex";
                    closeBtn.style.alignItems = "center";
                    closeBtn.style.justifyContent = "center";
                    closeBtn.style.borderRadius = "50%";
                    closeBtn.textContent = "X";
                    closeBtn.onclick = function() {
                    closeFloating();
                    };
                    floatingBanner.appendChild(closeBtn);
                }
                }, 1000);
            }, 3000);
            }
        };
        FloatingAds();

        function closeFloating() {
            var floatingBanner = window.parent.document.getElementById("floatingbanner");
            if (floatingBanner) {
            floatingBanner.style.transition = "left 1s ease-in";
            floatingBanner.style.left = "-500px";
            setTimeout(function() {
                floatingBanner.remove();
            }, 1300);
            }
        }

        // Script for close button (x)
        var scriptCode =
            function closeFloating() {
            var floatingBanner = window.parent.document.getElementById("floatingbanner");
            if (floatingBanner) {
                floatingBanner.style.transition = "left 1s ease-in";
                floatingBanner.style.left = "-500px";
                setTimeout(function() {
                floatingBanner.remove();
                }, 1300);
            }
            }

        var parentElement = window.parent.document.getElementsByTagName('body')[0];
        if (parentElement) {
            var scriptElement = window.parent.document.createElement('script');
            scriptElement.innerHTML = scriptCode;
            parentElement.appendChild(scriptElement);
        }
        `);
        idnLiveEmbedScript.appendChild(node);
        document.getElementsByTagName('head')[0]?.appendChild(idnLiveEmbedScript);
    }

    if (platform === "Mobile") {
        displayMobileSlot();
        defineMobileSlot();
        if(publisher === 'IDNTimes') {
            outstreamVideo();
        }
    } else if (platform === 'Desktop') {
        displayDesktopSlot();
        defineDesktopSlot();
    }

}
