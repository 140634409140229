import { Channels } from './views/channels';
import './views/zones/megabillboard/index.css';
import './views/zones/inarticle/mobile/index.css';

function app(window) {

    let configurations = {
        someDefaultConfiguration: false
    };
    let globalObject = window[window['IDNADS']];
    let queue = globalObject.q;

    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";

    head.appendChild(script);
    
    let isStaging = queue[1] ? queue[1].includes('ads.sateklopo.com') : false;
    globalObject = apiHandler(queue[0][0], queue[0][1], queue[0][2], queue[0][3], isStaging);
}

function apiHandler(publisher, channel, platform, topics, isStaging) {
    Channels(publisher, channel, platform, topics, isStaging);
}

function extendObject(a, b) {
    for (var key in b)
        if (b.hasOwnProperty(key))
            a[key] = b[key];
    return a;
}

app(window);