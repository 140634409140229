export class Helpers {
  constructor() { }

  appendChild(code, idx) {
    let head = document.getElementsByTagName("head")[0];
    let s = document.createElement("script");
    s.id = `idnads-slot-${idx}`
    try {
      s.appendChild(document.createTextNode(code));
      head.appendChild(s);
    } catch (e) {
      s.text = code;
      head.appendChild(s);
      k;
    }
  }
}

export async function fetchData(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error(error.message);
  }
}

export const campaigns = (publisher) => {
  switch (publisher) {
    case "Popbela":
      return [
        {
          type: '',
          ids: []
        }
      ];
    case "Popmama":
      return [
        {
          type: '',
          ids: []
        }
      ];
    case "Fortune":
      return [
        {
          type: '',
          ids: []
        }
      ];
    case "Yummy":
      return [
        {
          type: '',
          ids: []
        }
      ];
    case "Duniaku":
      return [
        {
          type: '',
          ids: []
        }
      ];
    case "Dashboard":
      return [
        {
          type: 'banner',
          name: 'reedem-campaign',
          ids: [19, 20, 21, 22, 23, 24, 25, 26]
        }
      ]
    default:
      return [
        {
          type: '',
          ids: []
        }
      ];
  }
};

export const zones = (publisher, channel) => {
  switch (publisher) {
    case "Popbela":
      return {
        scrollHeight: {
          mb: "247",
          lb: "577",
        },
        navbarHeight: {
          desktop: "131",
          mobile: "129"
        },
        reviveZoneId: "7",
        partner1: {
          name: "MGID",
          id: "p/o/popbela.com",
          mid: "524933",
          widgetID: {
            desktop: "1612006",
            mobile: {
              is_infinite_scroll: "1640630",
              is_not_infinite_scroll: "1611998",
            },
            amp: "1612001",
          },
        },
        partner2: {
          name: "Showheroes",
          id: {
            desktop: 'AAE6vHq7cq7-ZoVF',
            mobile: 'AAFl3brHBhAkWJ1b'
          }
        }
      };
    case "Popmama":
      return {
        scrollHeight: {
          mb: "170",
          lb: "500",
        },
        navbarHeight: {
          desktop: "49.844",
          mobile: "52"
        },
        reviveZoneId: "10",
        partner1: {
          name: "MGID",
          id: "p/o/popmama.com",
          mid: "524934",
          widgetID: {
            desktop: "1612024",
            mobile: {
              is_infinite_scroll: "1640624",
              is_not_infinite_scroll: "1612021",
            },
            amp: "1612023",
          },
        },
        partner2: {
          name: "Showheroes",
          id: {
            desktop: 'AAE6xS7JThWpVLAk',
            mobile: 'AAFl3FLrXu4-lNcU'
          }
        }
      };
    case "Fortune":
      return {
        scrollHeight: {
          mb: "182",
          lb: "512",
        },
        navbarHeight: {
          desktop: "44",
          mobile: "64"
        },
        reviveZoneId: "13",
      };
    case "Yummy":
      return {
        scrollHeight: {
          mb: "218",
          lb: "548",
        },
        navbarHeight: {
          desktop: "72",
          mobile: "100"
        },
        reviveZoneId: "1",
      };
    case "Duniaku":
      return {
        scrollHeight: {
          mb: "170",
          lb: "500",
        },
        navbarHeight: {
          desktop: "174",
          mobile: "122"
        },
        reviveZoneId: "16",
      };
    default:
      return {
        scrollHeight: {
          mb: "200",
          lb: "530",
        },
        navbarHeight: {
          desktop: "155",
          mobile: "85.84"
        },
        reviveZoneId: "4",
        partner1: {
          name: "MGID",
          id: "i/d/idntimes.com",
          mid: "522611",
          widgetID: {
            desktop: "1611986",
            mobile: {
              is_infinite_scroll: "1640604",
              is_not_infinite_scroll: "1611968",
            },
            amp: "1645503",
          },
        },
        partner2: {
          name: "Showheroes",
          id: {
            desktop: channel === 'Automotive' || channel === 'Men' || channel === 'Sport' ? 'AAFnuzJrpdme9y4d' : channel === 'News' || channel === 'Business' ? 'AAFnjiiAmDx0pBOI' : 'AAFnvGxlxIMEh1SL',
            mobile: channel === 'Automotive' || channel === 'Men' || channel === 'Sport' ? 'AAFnulo-7rvHu_BD' : channel === 'News' || channel === 'Business' ? 'AAFnj3znbZfZYYTQ' : 'AAFnva8rQ7-849fh'
          } 
        }
      };
  }
};

export const zonesMonorepo = (publisher, channel) => {
  switch (publisher) {
    case "Popbela":
      return {
        scrollHeight: {
          mb: "247",
          lb: "577",
        },
        navbarHeight: {
          desktop: "131",
          mobile: "129"
        },
        font: "Futura",
        color: "#D61964",
        reviveZoneId: "7",
        partner1: {
          name: "MGID",
          id: "p/o/popbela.com",
          mid: "524933",
          widgetID: {
            desktop: "1612006",
            mobile: {
              is_infinite_scroll: "1640630",
              is_not_infinite_scroll: "1611998",
            },
            amp: "1612001",
          },
        },
        partner2: {
          name: "Showheroes",
          id: {
            desktop: 'AAE6vHq7cq7-ZoVF',
            mobile: 'AAFl3brHBhAkWJ1b'
          }
        }
      };
    case "Popmama":
      return {
        scrollHeight: {
          mb: "170",
          lb: "500",
        },
        navbarHeight: {
          desktop: "49.844",
          mobile: "52"
        },
        font: "Nunito",
        color: "#8476CD",
        reviveZoneId: "10",
        partner1: {
          name: "MGID",
          id: "p/o/popmama.com",
          mid: "524934",
          widgetID: {
            desktop: "1612024",
            mobile: {
              is_infinite_scroll: "1640624",
              is_not_infinite_scroll: "1612021",
            },
            amp: "1612023",
          },
        },
        partner2: {
          name: "Showheroes",
          id: {
            desktop: 'AAE6xS7JThWpVLAk',
            mobile: 'AAFl3FLrXu4-lNcU'
          }
        }
      };
    case "Fortune":
      return {
        scrollHeight: {
          mb: "182",
          lb: "512",
        },
        navbarHeight: {
          desktop: "74",
          mobile: "104"
        },
        font: "PT Serif",
        color: "#2C2E31",
        reviveZoneId: "13",
      };
    case "Yummy":
      return {
        scrollHeight: {
          mb: "218",
          lb: "548",
        },
        navbarHeight: {
          desktop: "72",
          mobile: "100"
        },
        font: "Roboto",
        color: "#F7941E",
        reviveZoneId: "1",
      };
    case "Duniaku":
      return {
        scrollHeight: {
          mb: "170",
          lb: "500",
        },
        navbarHeight: {
          desktop: "174",
          mobile: "122"
        },
        color: "#ED2227",
        font: "PT Serif",
        reviveZoneId: "16",
      };
    default:
      return {
        scrollHeight: {
          mb: "200",
          lb: "530",
        },
        navbarHeight: {
          desktop: "75",
          mobile: "82"
        },
        font: "Poppins",
        color: "#ED2227",
        reviveZoneId: "4",
        partner1: {
          name: "MGID",
          id: "i/d/idntimes.com",
          mid: "522611",
          widgetID: {
            desktop: "1611986",
            mobile: {
              is_infinite_scroll: "1640604",
              is_not_infinite_scroll: "1611968",
            },
            amp: "1645503",
          },
        },
        partner2: {
          name: "Showheroes",
          id: {
            desktop: channel === 'Automotive' || channel === 'Men' || channel === 'Sport' ? 'AAFnuzJrpdme9y4d' : channel === 'News' || channel === 'Business' ? 'AAFnjiiAmDx0pBOI' : 'AAFnvGxlxIMEh1SL',
            mobile: channel === 'Automotive' || channel === 'Men' || channel === 'Sport' ? 'AAFnulo-7rvHu_BD' : channel === 'News' || channel === 'Business' ? 'AAFnj3znbZfZYYTQ' : 'AAFnva8rQ7-849fh'
          } 
        }
      };
  }
};

export function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, ''); 
  str = str.toLowerCase(); 
  str = str.replace(/[^a-z0-9 -]/g, '') 
      .replace(/\s+/g, '-') 
      .replace(/-+/g, '-'); 
  return str;
}

export const stickyCodeMegabillboardV1 = (scrollHeight, interval, intervalHideCloseButton, index) => {
  return interval !== null ? `
    var firstVisit = true;
    var intervalMB = ${interval}
    var intervalCloseButton = ${intervalHideCloseButton}
    var isSticky3s = false;

    function showCloseButton() {
        let closeButtonMB = document.getElementById("idnads_btn-close${index ? `-${index}` : ''}");
        let emptyDiv = document.getElementById("empty-div-header-mb");
        if (closeButtonMB !== null) {
            closeButtonMB.style.display = "flex";
        }
        if (emptyDiv !== null) {
            emptyDiv.style.display = "flex";
        }
    }

    function hideCloseButton() {
        let closeButtonMB = document.getElementById("idnads_btn-close${index ? `-${index}` : ''}");
        let emptyDiv = document.getElementById("empty-div-header-mb");
        if (closeButtonMB !== null) {
            closeButtonMB.style.display = "none";
        }
        if (emptyDiv !== null) {
            emptyDiv.style.display = "none";
        }
    }

    if (intervalCloseButton && intervalCloseButton !== null) {
        hideCloseButton();
    } else {
        showCloseButton();
    }

    googletag.pubads().addEventListener("impressionViewable", (event) => {

        function backToDefault() {
            let idnadsHeader = document.getElementById("idnads_header${index ? `_IF_${index}` : ''}");
            let mb = document.getElementById("div-gpt-ad-mega_billboard${index ? `-${index}` : ''}");
            let placeholder_mb = document.getElementById("idnads_placeholder_mb");
            if(placeholder_mb !== null) {
                placeholder_mb.classList.remove("show_placeholder_mb");
            }
            isSticky3s = false;
        }

        function resizeAdsTo1x1() {
          let idnadsHeader = document.getElementById("idnads_header${index ? `_IF_${index}` : ''}");
            let mb = document.getElementById("div-gpt-ad-mega_billboard${index ? `-${index}` : ''}");
            let placeholder_mb = document.getElementById("idnads_placeholder_mb");
            let wrapper_mb = document.getElementById("wrapper-IDN_MegaBillboard${index ? index : ''}");
            if (idnadsHeader !== null) {
              idnadsHeader.children[0].style.display = "none";
              idnadsHeader.classList.remove("ad-mega_billboard-container");
              idnadsHeader.style.height = 0;
            }
            if (wrapper_mb !== null) {
              wrapper_mb.style.minHeight = 0;
            }
            if (mb !== null) {
              mb.classList.add("ad-mega_billboard-smaller");
            }
            if(placeholder_mb !== null) {
              placeholder_mb.classList.remove("show_placeholder_mb");
            }
            isSticky3s = true;
        }
    
        function scrollFunction() {
            if (window.scrollY > document.getElementById('IDN_MegaBillboard${index ? index : ''}').offsetHeight + document.getElementById('IDN_MegaBillboard${index ? index : ''}').offsetTop) { 
                if (firstVisit) {
                    document.getElementById("idnads_header${index ? `_IF_${index}` : ''}").classList.add("ad-mega_billboard-container")
                    document.getElementById("div-gpt-ad-mega_billboard${index ? `-${index}` : ''}").classList.add("ad-mega_billboard")
                    document.getElementById("idnads_placeholder_mb").classList.add("show_placeholder_mb")
                    setTimeout(resizeAdsTo1x1, ${interval})
                    if (${intervalHideCloseButton !== null} && document.getElementsByClassName("ad-mega_billboard-container")[0] !== null) {
                        hideCloseButton();
                    }
                    if (${intervalHideCloseButton && intervalHideCloseButton !== null}) {
                        setTimeout(showCloseButton, ${intervalHideCloseButton})
                    }
                    firstVisit = false;
                }
            } else {
                backToDefault();
            }
        }

        document.addEventListener('scroll', function(event) {
            event.preventDefault();
            scrollFunction();
        });
    });
  ` : ''
}

export const stickyCodeInarticle1V1 = (index) => {
  return `
    googletag.pubads().addEventListener("impressionViewable", (event) => {
        let idnadsInarticleHeader = document.getElementById("idnads_header_IA${index ? `-${index}` : ''}");
        let ia1 = document.getElementById("div-gpt-ad-inarticle1${index ? `-${index}` : ''}");
        let idSlot = document.getElementById("IDN_InArticle1${index ? `-${index}` : ''}");
        let scrollBoxIa = document.getElementById("ads-scrollpage-box${index ? `-${index}` : ''}");
        let scrollPageContainerIa = document.getElementById("ads-scrollpage-container${index ? `-${index}` : ''}");

        function backToDefault() {
            if (idnadsInarticleHeader !== null) {
              idnadsInarticleHeader.children[0].style.display = "flex";
              idnadsInarticleHeader.style.height = "657px";
            }
            if (ia1 !== null) {
              ia1.classList.remove("ad-inarticle-smaller");
            }
            if (idSlot !== null) {
              idSlot.classList.remove("ad-inarticle-container-sticky");
            }
            if (scrollBoxIa !== null) {
              scrollBoxIa.classList.remove("ad-inarticle-box-smaller");
            }
            if (scrollPageContainerIa !== null) {
              scrollPageContainerIa.classList.remove("ad-inarticle-placeholder-hide")
            }
        }

        function resizeAdsTo1x1() {
            if (idnadsInarticleHeader !== null) {
              idnadsInarticleHeader.children[0].style.display = "none";
              idnadsInarticleHeader.style.height = 0;
            }
            if (ia1 !== null) {
              ia1.classList.add("ad-inarticle-smaller");
            }
            if (idSlot !== null) {
              idSlot.classList.add("ad-inarticle-container-sticky");
            }
            if (scrollBoxIa !== null) {
              scrollBoxIa.classList.add("ad-inarticle-box-smaller");
            }
            if (scrollPageContainerIa !== null) {
              scrollPageContainerIa.classList.add("ad-inarticle-placeholder-hide");
            }
        }
    
        function scrollIaFunction() {
            if (window.scrollY > idSlot.offsetHeight + idSlot.offsetTop) { 
              resizeAdsTo1x1();
            } else {
              backToDefault();
            }
        }

        document.addEventListener('scroll', function(event) {
            event.preventDefault();
            scrollIaFunction();
        });
    });
  `
}
